import React from "react";
import PropTypes from "prop-types";
import { FaCalendar } from "react-icons/fa/";
import { FaUser } from "react-icons/fa/";
import { FaTag } from "react-icons/fa/";

const Metadata = props => {
  const { author: authorName, category, date, theme } = props;

  return (
    <p className="meta">
      <span>
        <FaCalendar size={18} /> {date}
      </span>
      <span>
        <FaUser size={18} /> {authorName}
      </span>
      {category && (
        <span>
          <FaTag size={18} /> {category}
        </span>
      )}

      {/* --- STYLES --- */}
      <style jsx>{`
        .meta {
          display: flex;
          flex-flow: row wrap;
          font-size: 0.8em;
          padding: ${theme.space.m} ${theme.space.m};
          background: transparent;
          letter-spacing: 0.1rem;
        }

        .meta :global(svg) {
          fill: ${theme.color.neutral.category_blue};
          margin: ${theme.space.inline.xs};
        }

        .meta span {
          align-items: center;
          display: flex;
          text-transform: uppercase;
          margin: ${theme.space.xs} ${theme.space.m} ${theme.space.xs} 0;
        }
      `}</style>

    </p>
  );
};

Metadata.propTypes = {
  author: PropTypes.string.isRequired,
  category: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  theme: PropTypes.object.isRequired
}

export default Metadata;
