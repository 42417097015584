import React from "react";
import PropTypes from "prop-types";

const Headline = props => {
  const { title, children, theme } = props;

  return (
    <React.Fragment>
      {title ? <h1>{title}</h1> : <h1>{children}</h1>}

      {/* --- STYLES --- */}
      <style jsx>{`
        h1 {
          font-weight: 700;
          font-size: ${theme.font.size.xxxl};
          margin: ${theme.space.stack.l};
          animation-name: headlineEntry;
          animation-duration: ${theme.time.duration.long};
          word-break: break-word;
        }

        @keyframes headlineEntry {
          from {
            opacity: 0.5;
          }
          to {
            opacity: 1;
          }
        }
      `}</style>
    </React.Fragment>
  );
};

Headline.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.object,
  theme: PropTypes.object.isRequired
}

export default Headline;
