import React from "react"
import PropTypes from "prop-types";
import Layout from "../layouts/layout"
import Post from "../components/post/post"
import SEO from "../components/seo/seo"
import themeObject from "../theme/theme.yaml"
import { graphql } from 'gatsby'
import "prismjs/themes/prism.css";

const blogPostTemplate = props => {
  const {
    data: {
      post
    }
  } = props;

  return (
    <React.Fragment>
          <Layout>
            <SEO 
              title={post.frontmatter.title} 
              description={post.frontmatter.description}
              image={post.frontmatter.featuredImage.childImageSharp.fluid.src}
              article={true}
              dateCreated={post.frontmatter.date_unformatted}
              dateModified={post.frontmatter.modified}
            />
            <Post post={post} theme={themeObject} />
          </Layout>
    </React.Fragment>
  );
};

export default blogPostTemplate;

blogPostTemplate.propTypes = {
  data: PropTypes.object.isRequired
};

export const postQuery = graphql`
  query PostBySlug($slug: String!) {
    post: markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      html
      excerpt
      fields {
        slug
      }
      frontmatter {
        title
        author
        category
        status
        date(formatString: "DD.MM.YYYY")
        date_unformatted: date
        modified
        description
        featuredImage {
          childImageSharp {
            fluid(maxWidth: 800, maxHeight: 360) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`;