import React from "react";
import PropTypes from "prop-types";
import Metadata from "./metadata"
import Headline from "./headline"

const Post = props => {
    const {
      theme,
      post: {
        html,
        //fields: { slug },
        frontmatter: 
          { 
            title, 
            author, 
            category, 
            date
          }
      }
    } = props;

    return (
        <React.Fragment>
          <div className="blogpost">
            <header>
                <Headline title={title} theme={theme} />
                <Metadata author={author} category={category} date={date} theme={theme}/>
            </header>
            <div className="bodytext" dangerouslySetInnerHTML={{ __html: html }} />
          </div>

          {/* --- STYLES --- */}
          <style jsx>{`
            .blogpost {
              padding: ${`calc(${theme.space.default} * 2 + 90px) ${theme.space.default} calc(${
                theme.space.default
              } * 2)`};
              margin: ${`calc(${theme.header.height.homepage} + ${theme.space.xl})`} auto ${theme.space.xl};
              width: 95vw;
              max-width: ${`calc(${theme.text.maxWidth.desktop} *2)`};
              background-color: ${theme.color.background.white_opac};
            }

            .imageContainer {
              width: 100%;
              
            }

            .bodytext {
              animation-name: bodytextEntry;
              animation-duration: ${theme.time.duration.long};
              text-align: justify;
              word-break: break-word;
              color: ${theme.color.bodytext};
            }

            .bodytext :global(h2),
            .bodytext :global(h3) {
              margin: 1.5em 0 1em;
            }
    
            .bodytext :global(h2) {
              line-height: ${theme.font.lineHeight.s};
              font-size: ${theme.font.size.l};
            }
    
            .bodytext :global(h3) {
              font-size: ${theme.font.size.m};
              line-height: ${theme.font.lineHeight.m};
            }
    
            .bodytext :global(p) {
              font-size: ${theme.font.size.xs};
              line-height: ${theme.font.lineHeight.xxl};
              margin: 0 0 1.5em;
            }
            
            .bodytext :global(ul) {
              list-style: circle;
              margin: 0 0 1.5em;
              padding: 0 0 0 1.5em;
            }

            .bodytext :global(li) {
              margin: 0.7em 0;
              line-height: 1.5;
            }

            .bodytext :global(a) {
              font-weight: ${theme.font.weight.bold};
              text-decoration: underline;
            }

            .bodytext :global(a.gatsby-resp-image-link) {
              border: 0;
              display: block;
              margin: 2.5em 0;
              border-radius: ${theme.size.radius.default};
              overflow: hidden;
              border: 1px solid grey
            }
    
            @keyframes bodytextEntry {
              from {
                opacity: 0;
              }
              to {
                opacity: 1;
              }
            }
          `}</style>
        </React.Fragment>
    );
};

Post.propTypes = {
  theme: PropTypes.object.isRequired,
  post: PropTypes.object.isRequired
};

export default Post;

